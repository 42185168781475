import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Input from "../components/common/Input";
import Button from "../components/common/Button";
import { countryList } from "../constants/country";
import { emailValidate, passwordValidate, isNumber } from "../utils/validator";
import { API } from "../config/api";
import { isLoggedIn, getUserData } from "../utils/userData";
import { OpenEye, CloseEye } from "../components/svgs";

export default function SignUp() {
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phonenumber: "",
    password: "",
    confirm_password: "",
  });
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [invalidPwd, setInvalidPwd] = useState(false);
  const [checkAuth, setCheckAuth] = useState(true);
  const [phoneCode, setPhoneCode] = useState("+93");

  useEffect(() => {
    if (isLoggedIn() && getUserData()) {
      navigate("/");
    } else {
      setCheckAuth(false);
    }
  }, []);

  const handleChange = (evt) => {
    setError(null);
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleSignUp = async () => {
    const {
      username,
      password,
      first_name,
      last_name,
      phonenumber,
      confirm_password,
      email,
    } = state;
    if (!username && !password && !first_name && !last_name && !phonenumber) {
      setError("All fields are required");
    } else if (!emailValidate(email)) {
      setError("Invalid e-mail address");
    } else if (!isNumber(phonenumber)) {
      setError("Phone should be number");
    } else if (!passwordValidate(password)) {
      setInvalidPwd(true);
      setError("Invalid password format");
    } else if (password !== confirm_password) {
      setError("Password entries do not match");
      setInvalidPwd(false);
    } else {
      setLoading(true);
      setError(null);
      setInvalidPwd(false);

      const data = {
        ...state,
        username: username.toLowerCase(),
        email: email.toLowerCase(),
        phonenumber: `${phoneCode}${phonenumber}`,
      };

      try {
        const res = await API.post("/signup", data);

        navigate("/verification", {
          state: { data: data, session: res.data?.sessionhash },
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data) {
          const error = JSON.stringify(err?.response?.data);
          if (
            error.includes("email") &&
            error.includes("username") &&
            error.includes("unique")
          ) {
            setError(
              "An account with this username and email has already been used. Please try again."
            );
          } else if (error.includes("email") && error.includes("unique")) {
            setError(
              "An account with this email has already been used. Please try again."
            );
          } else if (error.includes("username") && error.includes("unique")) {
            setError(
              "An account with this username has already been used. Please try again."
            );
          } else {
            setError(err.response.data?.error);
          }
        } else {
          setError("Something went wrong, try again!");
        }
      }
    }
  };

  if (checkAuth) return <div></div>;

  return (
    <Layout>
      <div className="mb-[200px] relative">
        <div className="absolute hidden md:block top-0 right-0 bottom-0 left-0 -z-10 min-h-[313px]  wave">
          <StaticImage
            src="../images/hero.webp"
            alt="contact-us"
            placeholder="blurred"
          />
        </div>
        <div className="max-w-[1040px] mx-5 xl:mx-auto border pb-[70px] pt-[52px] pl-5 pr-5 sm:pl-[68px] sm:pr-[68px] md:pr-0 mt-[26px] bg-[#16171B]  border-white/30 rounded-[28px]">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-[60%] lg:w-full">
              <StaticImage
                src="../images/logo.png"
                placeholder="blurred"
                alt="logo"
                height={54}
              />
              <p className="mt-[23px] text-[20px] leading-[30px] font-medium tracking-[0.135em] text-white">
                Sign Up Now
              </p>
              <p className="mt-[10px] text-sm leading-[21px] font-normal tracking-[0.135em] text-white/30">
                Sign up now to open your account.
              </p>
              {error !== null && (
                <span className="text-red-700 opacity-80 -mb-5   block font-bold text-sm">
                  {error}
                </span>
              )}
              <div className="mt-[27.12px]">
                <Input
                  placeholder="Username"
                  type="text"
                  name={"username"}
                  width="w-full sm:w-[327px]"
                  value={state.username}
                  onChange={handleChange}
                />
                <br />
                <Input
                  placeholder="First Name"
                  type="text"
                  name={"first_name"}
                  mt="mt-[9px]"
                  width="w-full sm:w-[327px]"
                  value={state.first_name}
                  onChange={handleChange}
                />
                <br />
                <Input
                  placeholder="Last Name"
                  type="text"
                  mt="mt-[9px]"
                  name={"last_name"}
                  width="w-full sm:w-[327px]"
                  value={state.last_name}
                  onChange={handleChange}
                />
                <Input
                  placeholder="Email Address"
                  type="email"
                  mt="mt-[9px]"
                  name={"email"}
                  width="w-full sm:w-[327px]"
                  value={state.email}
                  onChange={handleChange}
                />
                <div className="flex items-center mt-[9px]">
                  <select
                    value={phoneCode}
                    onChange={(e) => setPhoneCode(e.target.value)}
                    className="max-w-[96px] h-[50px] appearance-none bg-[#202125] text-xs rounded-[25px]"
                  >
                    {countryList.map((item, index) => (
                      <option value={item.dial_code} key={index}>
                        {item.dial_code}
                      </option>
                    ))}
                  </select>

                  <Input
                    placeholder="Enter Phone Number"
                    type="text"
                    ml="ml-[10px]"
                    width="w-full sm:w-[222px]"
                    name={"phonenumber"}
                    value={state.phonenumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex items-center w-full sm:w-[327px] mt-[9px] text-[13px] px-[26px] leading-[20px] font-bold tracking-[0.135em] text-white  h-[50px] bg-alto/5 rounded-[25px]">
                  <input
                    className="bg-transparent tracking-[0.135em] w-full sm:w-[327px] outline-none border-none"
                    type={showPwd ? "text" : "password"}
                    value={state.password}
                    name="password"
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <span onClick={() => setShowPwd(!showPwd)}>
                    {showPwd ? <CloseEye /> : <OpenEye />}
                  </span>
                </div>

                <div className="flex items-center w-full sm:w-[327px] mt-[9px] text-[13px] px-[26px] leading-[20px] font-bold tracking-[0.135em] text-white  h-[50px] bg-alto/5 rounded-[25px]">
                  <input
                    className="bg-transparent tracking-[0.135em] w-full sm:w-[327px] outline-none border-none"
                    type={showConfirmPwd ? "text" : "password"}
                    value={state.confirm_password}
                    onChange={handleChange}
                    name="confirm_password"
                    placeholder="Re-Type Password"
                  />
                  <span onClick={() => setShowConfirmPwd(!showConfirmPwd)}>
                    {showConfirmPwd ? <CloseEye /> : <OpenEye />}
                  </span>
                </div>

                <p
                  className={`mt-[11px] text-[11.34px] leading-[17px] font-normal ${
                    invalidPwd ? "text-red-500" : "text-[#656565]"
                  }  w-[300px] sm:w-[327px] mb-5`}
                >
                  Please note. This account will have access to your assets. We
                  recomend all lower case words (4 minimum) with a space between
                  each word. Example: “smith college stefan football princeton”
                </p>

                <Button
                  text={loading ? "Loading..." : "next"}
                  width="w-[170px]"
                  onClick={handleSignUp}
                  disable={loading}
                />
                {/* <Link to="/verification">
                </Link> */}
              </div>
            </div>
            {/* Middle Line */}
            <div className="h-[1px] w-full md:w-[1px] md:h-[385px] bg-white mb-11 mt-[110px] md:mb-0 " />
            {/* End */}
            <div className="w-full md:w-[40%] lg:w-full flex flex-col items-center justify-center">
              <p className="text-sm lg:text-base leading-6 font-normal tracking-[0.135em] text-white text-center w-[260px] mb-[29px]">
                Already Have an account on
                <span className="txt-gradient-yellow"> ThunderStake?</span>
              </p>
              <Link to="/signin">
                <Button text="LOGIN" width="w-[170px]" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
